import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import dhyana2Cover from "./assets/projectImage/dhyana2/dhyana2Cover.png";
import dhyana2CoverWebp from "./assets/images/projectWebp/dhyana2/dhyana2CoverWebp.webp";
import dhyana2CoverAVIF from "./assets/images/projectAVIF/dhyana2/dhyana2CoverAVIF.avif";
import dhyana2Section3One from "./assets/projectImage/dhyana2/dhyana2Section3One.png";
import dhyana2Section3Two from "./assets/projectImage/dhyana2/dhyana2Section3Two.png";
import dhyana2Section8Img from "./assets/projectImage/dhyana2/dhyana2Section8Img.png";
import dhyana2Section3OneWebp from "./assets/images/projectWebp/dhyana2/dhyana2Section3OneWebp.webp";
import dhyana2Section3TwoWebp from "./assets/images/projectWebp/dhyana2/dhyana2Section3TwoWebp.webp";
import dhyana2Section8ImgWebp from "./assets/images/projectWebp/dhyana2/dhyana2Section8ImgWebp.webp";
import dhyana2Section3OneAVIF from "./assets/images/projectAVIF/dhyana2/dhyana2Section3OneAVIF.avif";
import dhyana2Section3TwoAVIF from "./assets/images/projectAVIF/dhyana2/dhyana2Section3TwoAVIF.avif";
import dhyana2Section8ImgAVIF from "./assets/images/projectAVIF/dhyana2/dhyana2Section8ImgAVIF.avif";
import dhyana2SmallVid from "./assets/projectImage/dhyana2/dhyana2SmallVid.mp4";
import dhyana2VideoOne from "./assets/videos/dhyana2VideoOne.mp4";

import dhyana1Cover from "./assets/projectImage/dhyana1/dhyana1Cover.png";
import dhyana1CoverWebp from "./assets/images/projectWebp/dhyana1/dhyana1CoverWebp.webp";
import dhyana1CoverAVIF from "./assets/images/projectAVIF/dhyana1/dhyana1CoverAVIF.avif";
import dhyana1Section3One from "./assets/projectImage/dhyana1/dhyana1Section3One.png";
import dhyana1Section3Two from "./assets/projectImage/dhyana1/dhyana1Section3Two.png";
import dhyana1Section3OneAVIF from "./assets/images/projectAVIF/dhyana1/dhyana1Section3OneAVIF.avif";
import dhyana1Section3TwoAVIF from "./assets/images/projectAVIF/dhyana1/dhyana1Section3TwoAVIF.avif";
import dhyana1Section3OneWebp from "./assets/images/projectWebp/dhyana1/dhyana1Section3OneWebp.webp";
import dhyana1Section3TwoWebp from "./assets/images/projectWebp/dhyana1/dhyana1Section3TwoWebp.webp";
import dhyana1Section7Img from "./assets/projectImage/dhyana1/dhyana1Section7Img.png";
import dhyana1Section7ImgAVIF from "./assets/images/projectAVIF/dhyana1/dhyana1Section7ImgAVIF.avif";
import dhyana1Section7ImgWebp from "./assets/images/projectWebp/dhyana1/dhyana1Section7ImgWebp.webp";
import dhyana1Section10Img from "./assets/projectImage/dhyana1/dhyana1Section10Img.png";
import dhyana1Section10ImgAVIF from "./assets/images/projectAVIF/dhyana1/dhyana1Section10ImgAVIF.avif";
import dhyana1Section10ImgWebp from "./assets/images/projectWebp/dhyana1/dhyana1Section10ImgWebp.webp";
import dhyana1Section11Img from "./assets/projectImage/dhyana1/dhyana1Section11Img.png";
import dhyana1Section11ImgAVIF from "./assets/images/projectAVIF/dhyana1/dhyana1Section11ImgAVIF.avif";
import dhyana1Section11ImgWebp from "./assets/images/projectWebp/dhyana1/dhyana1Section11ImgWebp.webp";
import dhyana1BottomImg from "./assets/projectImage/dhyana1/dhyana1BottomImg.png";
import dhyana1BottomImgAVIF from "./assets/images/projectAVIF/dhyana1/dhyana1BottomImgAVIF.avif";
import dhyana1BottomImgWebp from "./assets/images/projectWebp/dhyana1/dhyana1BottomImgWebp.webp";
import dhyana1VideoOne from "./assets/videos/dhyana1VideoOne.mp4";
import dhyana1VideoTwo from "./assets/videos/dhyana1VideoTwo.mp4";

import holobotCover from "./assets/projectImage/holobot/holobotCover.png";
import holobotCoverWebp from "./assets/images/projectWebp/holobot/holobotCoverWebp.webp";
import holobotCoverAVIF from "./assets/images/projectAVIF/holobot/holobotCoverAVIF.avif";
import holobotSection3One from "./assets/projectImage/holobot/holobotSection3One.png";
import holobotSection3Two from "./assets/projectImage/holobot/holobotSection3Two.png";
import holobotSection3OneAVIF from "./assets/images/projectAVIF/holobot/holobotSection3OneAVIF.avif";
import holobotSection3TwoAVIF from "./assets/images/projectAVIF/holobot/holobotSection3TwoAVIF.avif";
import holobotSection3OneWebp from "./assets/images/projectWebp/holobot/holobotSection3OneWebp.webp";
import holobotSection3TwoWebp from "./assets/images/projectWebp/holobot/holobotSection3TwoWebp.webp";
import holobotSection8Image from "./assets/projectImage/holobot/holobotSection8Image.png";
import holobotSection8ImageAVIF from "./assets/images/projectAVIF/holobot/holobotSection8ImageAVIF.avif";
import holobotSection8ImageWebp from "./assets/images/projectWebp/holobot/holobotSection8ImageWebp.webp";
import holobotVideoOne from "./assets/videos/holobotVideoOne.mp4";

import odishaCover from "./assets/projectImage/odisha/odishaCover.png";
import odishaCoverAVIF from "./assets/images/projectAVIF/odisha/odishaCoverAVIF.avif";
import odishaCoverWebp from "./assets/images/projectWebp/odisha/odishaCoverWebp.webp";
import odishaSection3One from "./assets/projectImage/odisha/odishaSection3One.png";
import odishaSection3Two from "./assets/projectImage/odisha/odishaSection3Two.png";
import odishaSection3OneAVIF from "./assets/images/projectAVIF/odisha/odishaSection3OneAVIF.avif";
import odishaSection3TwoAVIF from "./assets/images/projectAVIF/odisha/odishaSection3TwoAVIF.avif";
import odishaSection3OneWebp from "./assets/images/projectWebp/odisha/odishaSection3OneWebp.webp";
import odishaSection3TwoWebp from "./assets/images/projectWebp/odisha/odishaSection3TwoWebp.webp";
import odishaSection8Image from "./assets/projectImage/odisha/odishaSection8Image.png";
import odishaSection8ImageAVIF from "./assets/images/projectAVIF/odisha/odishaSection8ImageAVIF.avif";
import odishaSection8ImageWebp from "./assets/images/projectWebp/odisha/odishaSection8ImageWebp.webp";
import odishaSection10Img from "./assets/projectImage/odisha/odishaSection10Img.png";
import odishaSection10ImgAVIF from "./assets/images/projectAVIF/odisha/odishaSection10ImgAVIF.avif";
import odishaSection10ImgWebp from "./assets/images/projectWebp/odisha/odishaSection10ImgWebp.webp";
import odishaBottomImg from "./assets/projectImage/odisha/odishaBottomImg.png";
import odishaBottomImgAVIF from "./assets/images/projectAVIF/odisha/odishaBottomImgAVIF.avif";
import odishaBottomImgWebp from "./assets/images/projectWebp/odisha/odishaBottomImgWebp.webp";
import naveenVideone from "./assets/videos/naveenVideone.mp4";

import einCover from "./assets/projectImage/ein/einCover.png";
import einCoverWebp from "./assets/images/projectWebp/ein/einCoverWebp.webp";
import einCoverAVIF from "./assets/images/projectAVIF/ein/einCoverAVIF.avif";
import einSection8Image from "./assets/projectImage/ein/einSection8Image.png";
import einSection8ImageAVIF from "./assets/images/projectAVIF/ein/einSection8ImageAVIF.avif";
import einSection8ImageWebp from "./assets/images/projectWebp/ein/einSection8ImageWebp.webp";
import einSection10Img from "./assets/projectImage/ein/einSection10Img.png";

import einSection10ImgAVIF from "./assets/images/projectAVIF/ein/einSection10ImgAVIF.avif";
import einSection10ImgWebp from "./assets/images/projectWebp/ein/einSection10ImgWebp.webp";
import einSection11Img from "./assets/projectImage/ein/einSection11Img.png";
import einSection11ImgAVIF from "./assets/images/projectAVIF/ein/einSection11ImgAVIF.avif";
import einSection11ImgWebp from "./assets/images/projectWebp/ein/einSection11ImgWebp.webp";
import einBottomImg from "./assets/projectImage/ein/einBottomImg.png";
import einBottomImgWebp from "./assets/images/projectWebp/ein/einBottomImgWebp.webp";
import einBottomImgAVIF from "./assets/images/projectAVIF/ein/einBottomImgAVIF.avif";
import einVideoOne from "./assets/videos/einVideoOne.mp4";

import axarCover from "./assets/projectImage/axar/axarCover.png";
import axarCoverWebp from "./assets/images/projectWebp/axar/axarCoverWebp.webp";
import axarCoverAVIF from "./assets/images/projectAVIF/axar/axarCoverAVIF.avif";
import axarSection3One from "./assets/projectImage/axar/axarSection3One.png";
import axarSection3Two from "./assets/projectImage/axar/axarSection3Two.png";
import axarSection3OneWebp from "./assets/images/projectWebp/axar/axarSection3OneWebp.webp";
import axarSection3OneAVIF from "./assets/images/projectAVIF/axar/axarSection3OneAVIF.avif";
import axarSection3TwoWebp from "./assets/images/projectWebp/axar/axarSection3TwoWebp.webp";
import axarSection3TwoAVIF from "./assets/images/projectAVIF/axar/axarSection3TwoAVIF.avif";
import axarSection8Image from "./assets/projectImage/axar/axarSection8Image.png";
import axarSection8ImageAVIF from "./assets/images/projectAVIF/axar/axarSection8ImageAVIF.avif";
import axarSection8ImageWebp from "./assets/images/projectWebp/axar/axarSection8ImageWebp.webp";
import axarBottomImg from "./assets/projectImage/axar/axarBottomImg.png";
import axarBottomImgWebp from "./assets/images/projectWebp/axar/axarBottomImgWebp.webp";
import axarBottomImgAVIF from "./assets/images/projectAVIF/axar/axarBottomImgAVIF.avif";
import axarVideoOne from "./assets/videos/axarVideoOne.mp4";

import inviteCMCover from "./assets/projectImage/inviteCM/inviteCMCover.png";
import inviteCMCoverWebp from "./assets/images/projectWebp/inviteCM/inviteCMCoverWebp.webp";
import inviteCMCoverAVIF from "./assets/images/projectAVIF/inviteCM/inviteCMCoverAVIF.avif";
import inviteCMSection3One from "./assets/projectImage/inviteCM/inviteCMSection3One.png";
import inviteCMSection3Two from "./assets/projectImage/inviteCM/inviteCMSection3Two.png";
import inviteCMSection3OneWebp from "./assets/images/projectWebp/inviteCM/inviteCMSection3OneWebp.webp";
import inviteCMSection3OneAVIF from "./assets/images/projectAVIF/inviteCM/inviteCMSection3OneAVIF.avif";
import inviteCMSection3TwoWebp from "./assets/images/projectWebp/inviteCM/inviteCMSection3TwoWebp.webp";
import inviteCMSection3TwoAVIF from "./assets/images/projectAVIF/inviteCM/inviteCMSection3TwoAVIF.avif";
import inviteCMSection8Image from "./assets/projectImage/inviteCM/inviteCMSection8Image.png";
import inviteCMSection8ImageWebp from "./assets/images/projectWebp/inviteCM/inviteCMSection8ImageWebp.webp";
import inviteCMSection8ImageAVIF from "./assets/images/projectAVIF/inviteCM/inviteCMSection8ImageAVIF.avif";
import inviteCmVideoOne from "./assets/videos/inviteCmVideoOne.mp4";

import CMtalksCover from "./assets/projectImage/cmtalks/CMtalksCover.png";
import CMtalksCoverWebp from "./assets/images/projectWebp/cMtalks/CMtalksCoverWebp.webp";
import CMtalksCoverAVIF from "./assets/images/projectAVIF/cMtalks/CMtalksCoverAVIF.avif";
import cmTalksSection4Img from "./assets/projectImage/cmtalks/cmTalksSection4Img.png";
import cmTalksSection4ImgAVIF from "./assets/images/projectAVIF/cMtalks/cmTalksSection4ImgAVIF.avif";
import cmTalksSection4ImgWebp from "./assets/images/projectWebp/cMtalks/cmTalksSection4ImgWebp.webp";
import cmTalksSection6Img1 from "./assets/projectImage/cmtalks/cmTalksSection6Img1.png";
import cmTalksSection6Img2 from "./assets/projectImage/cmtalks/cmTalksSection6Img2.png";
import cmTalksLast from "./assets/projectImage/cmtalks/cmTalksLast.png";
import cmTalksLastAVIF from "./assets/images/projectAVIF/cMtalks/cmTalksLastAVIF.avif";
import cmTalksLastWebp from "./assets/images/projectWebp/cMtalks/cmTalksLastWebp.webp";
import cMtalksVideoOne from "./assets/videos/cMtalksVideoOne.mp4";

import hebatnegarakuCover from "./assets/projectImage/hebatnegaraku/hebatnegarakuCover.png";
import hebatnegarakuCoverWebp from "./assets/images/projectWebp/hebat/hebatnegarakuCoverWebp.webp";
import hebatnegarakuCoverAVIF from "./assets/images/projectAVIF/hebat/hebatnegarakuCoverAVIF.avif";
import hebatnegarakuSection3One from "./assets/projectImage/hebatnegaraku/hebatnegarakuSection3One.png";
import hebatnegarakuSection3Two from "./assets/projectImage/hebatnegaraku/hebatnegarakuSection3Two.png";
import hebatnegarakuSection3OneWebp from "./assets/images/projectWebp/hebat/hebatnegarakuSection3OneWebp.webp";
import hebatnegarakuSection3OneAVIF from "./assets/images/projectAVIF/hebat/hebatnegarakuSection3OneAVIF.avif";
import hebatnegarakuSection3TwoWebp from "./assets/images/projectWebp/hebat/hebatnegarakuSection3TwoWebp.webp";
import hebatnegarakuSection3TwoAVIF from "./assets/images/projectAVIF/hebat/hebatnegarakuSection3TwoAVIF.avif";
import hebatnegarakuSection8Image from "./assets/projectImage/hebatnegaraku/hebatnegarakuSection8Image.png";
import hebatnegarakuSection8ImageWebp from "./assets/images/projectWebp/hebat/hebatnegarakuSection8ImageWebp.webp";
import hebatnegarakuSection8ImageAVIF from "./assets/images/projectAVIF/hebat/hebatnegarakuSection8ImageAVIF.avif";
import hebatnegarakuBottomImg from "./assets/projectImage/hebatnegaraku/hebatnegarakuBottomImg.png";
import hebatnegarakuBottomImgWebp from "./assets/images/projectWebp/hebat/hebatnegarakuBottomImgWebp.webp";
import hebatnegarakuBottomImgAVIF from "./assets/images/projectAVIF/hebat/hebatnegarakuBottomImgAVIF.avif";
import hebatnegarakuVideoOne from "./assets/videos/hebatnegarakuVideoOne.mp4";

import appStarCover from "./assets/projectImage/appStar/appStarCover.png";
import appStarCoverWebp from "./assets/images/projectWebp/appStar/appStarCoverWebp.webp";
import appStarCoverAVIF from "./assets/images/projectAVIF/appStar/appStarCoverAVIF.avif";
import appStarSection3One from "./assets/projectImage/appStar/appStarSection3One.png";
import appStarSection3Two from "./assets/projectImage/appStar/appStarSection3Two.png";
import appStarSection3OneAVIF from "./assets/images/projectAVIF/appStar/appStarSection3OneAVIF.avif";
import appStarSection3TwoAVIF from "./assets/images/projectAVIF/appStar/appStarSection3TwoAVIF.avif";
import appStarSection3OneWebp from "./assets/images/projectWebp/appStar/appStarSection3OneWebp.webp";
import appStarSection3TwoWebp from "./assets/images/projectWebp/appStar/appStarSection3TwoWebp.webp";
import appStarSection8Image from "./assets/projectImage/appStar/appStarSection8Image.png";
import appStarSection8ImageAVIF from "./assets/images/projectAVIF/appStar/appStarSection8ImageAVIF.avif";
import appStarSection8ImageWebp from "./assets/images/projectWebp/appStar/appStarSection8ImageWebp.webp";
import appstarBottomImg from "./assets/projectImage/appStar/appstarBottomImg.png";
import appstarBottomImgAVIF from "./assets/images/projectAVIF/appStar/appstarBottomImgAVIF.avif";
import appstarBottomImgWebp from "./assets/images/projectWebp/appStar/appstarBottomImgWebp.webp";
import appStarVideoOne from "./assets/videos/appStarVideoOne.mp4";

import dhunCover from "./assets/projectImage/dhun/dhunCover.png";
import dhunCoverWebp from "./assets/images/projectWebp/dhun/dhunCoverWebp.webp";
import dhunCoverAVIF from "./assets/images/projectAVIF/dhun/dhunCoverAVIF.avif";
import dhunSection3One from "./assets/projectImage/dhun/dhunSection3One.png";
import dhunSection3Two from "./assets/projectImage/dhun/dhunSection3Two.png";
import dhunSection3OneAVIF from "./assets/images/projectAVIF/dhun/dhunSection3OneAVIF.avif";
import dhunSection3TwoAVIF from "./assets/images/projectAVIF/dhun/dhunSection3TwoAVIF.avif";
import dhunSection3OneWebp from "./assets/images/projectWebp/dhun/dhunSection3OneWebp.webp";
import dhunSection3TwoWebp from "./assets/images/projectWebp/dhun/dhunSection3TwoWebp.webp";
import dhunSection6Img1 from "./assets/projectImage/dhun/dhunSection6Img1.png";
import dhunSection6Img2 from "./assets/projectImage/dhun/dhunSection6Img2.png";
import dhunSection8Image from "./assets/projectImage/dhun/dhunSection8Image.png";
import dhunSection8ImageWebp from "./assets/images/projectWebp/dhun/dhunSection8ImageWebp.webp";
import dhunSection8ImageAVIF from "./assets/images/projectAVIF/dhun/dhunSection8ImageAVIF.avif";

import saffolaCover from "./assets/projectImage/safola/saffolaCover.png";
import saffolaCoverWebp from "./assets/images/projectWebp/safolla/saffolaCoverWebp.webp";
import saffolaCoverAVIF from "./assets/images/projectAVIF/safolla/saffolaCoverAVIF.avif";
import saffolaSection3One from "./assets/projectImage/safola/saffolaSection3One.png";
import saffolaSection3Two from "./assets/projectImage/safola/saffolaSection3Two.png";
import saffolaSection3OneAVIF from "./assets/images/projectAVIF/safolla/saffolaSection3OneAVIF.avif";
import saffolaSection3TwoAVIF from "./assets/images/projectAVIF/safolla/saffolaSection3TwoAVIF.avif";
import saffolaSection3OneWebp from "./assets/images/projectWebp/safolla/saffolaSection3OneWebp.webp";
import saffolaSection3TwoWebp from "./assets/images/projectWebp/safolla/saffolaSection3TwoWebp.webp";
import saffolaSection6Img1 from "./assets/projectImage/safola/saffolaSection6Img1.png";
import saffolaSection6Img2 from "./assets/projectImage/safola/saffolaSection6Img2.png";
import saffolaSection6Img1AVIF from "./assets/images/projectAVIF/safolla/saffolaSection6Img1AVIF.avif";
import saffolaSection6Img2AVIF from "./assets/images/projectAVIF/safolla/saffolaSection6Img2AVIF.avif";
import saffolaSection6Img1Webp from "./assets/images/projectWebp/safolla/saffolaSection6Img1Webp.webp";
import saffolaSection6Img2Webp from "./assets/images/projectWebp/safolla/saffolaSection6Img2Webp.webp";
import saffolaSection8Image from "./assets/projectImage/safola/saffolaSection8Image.png";
import saffolaSection8ImageAVIF from "./assets/images/projectAVIF/safolla/saffolaSection8ImageAVIF.avif";
import saffolaSection8ImageWebp from "./assets/images/projectWebp/safolla/saffolaSection8ImageWebp.webp";
import safolaBottomImg from "./assets/projectImage/safola/safolaBottomImg.png";
import safolaBottomImgAVIF from "./assets/images/projectAVIF/safolla/safolaBottomImgAVIF.avif";
import safolaBottomImgWebp from "./assets/images/projectWebp/safolla/safolaBottomImgWebp.webp";
import saffolaVideoOne from "./assets/videos/saffolaVideoOne.mp4";

import kejriwalCover from "./assets/projectImage/kejriwal/kejriwalCover.png";
import kejriwalCoverWebp from "./assets/images/projectWebp/kejriwal/kejriwalCoverWebp.webp";
import kejriwalCoverAVIF from "./assets/images/projectAVIF/kejriwal/kejriwalCoverAVIF.avif";
import kejriwalsection8Image from "./assets/projectImage/kejriwal/kejriwalsection8Image.png";
import kejriwalsection8ImageWebp from "./assets/images/projectWebp/kejriwal/kejriwalsection8ImageWebp.webp";
import kejriwalsection8ImageAVIF from "./assets/images/projectAVIF/kejriwal/kejriwalsection8ImageAVIF.avif";
import kejriwalBottomImg from "./assets/projectImage/kejriwal/kejriwalBottomImg.png";
import kejriwalBottomImgWebp from "./assets/images/projectWebp/kejriwal/kejriwalBottomImgWebp.webp";
import kejriwalBottomImgAVIF from "./assets/images/projectAVIF/kejriwal/kejriwalBottomImgAVIF.avif";
import kejriwalSection13Img from "./assets/projectImage/kejriwal/kejriwalSection13Img.png";
import kejriwalSection13ImgWebp from "./assets/images/projectWebp/kejriwal/kejriwalSection13ImgWebp.webp";
import kejriwalSection13ImgAVIF from "./assets/images/projectAVIF/kejriwal/kejriwalSection13ImgAVIF.avif";
import kejriwalVideoOne from "./assets/videos/kejriwalVideoOne.mp4";
import kejriwalVideoTwo from "./assets/videos/kejriwalVideoTwo.mp4";

import chefCover from "./assets/projectImage/chef/chefCover.png";
import chefCoverWebp from "./assets/images/projectWebp/chef/chefCoverWebp.webp";
import chefCoverAVIF from "./assets/images/projectAVIF/chef/chefCoverAVIF.avif";
import chefSection8Img from "./assets/projectImage/chef/chefSection8Img.png";
import chefSection8ImgWebp from "./assets/images/projectWebp/chef/chefSection8ImgWebp.webp";
import chefSection8ImgAVIF from "./assets/images/projectAVIF/chef/chefSection8ImgAVIF.avif";
import chefSection10Img from "./assets/projectImage/chef/chefSection10Img.png";
import chefSection10ImgWebp from "./assets/images/projectWebp/chef/chefSection10ImgWebp.webp";
import chefSection10ImgAVIF from "./assets/images/projectAVIF/chef/chefSection10ImgAVIF.avif";    
import chefBottomImg from "./assets/projectImage/chef/chefBottomImg.png";
import chefBottomImgWebp from "./assets/images/projectWebp/chef/chefBottomImgWebp.webp";
import chefBottomImgAVIF from "./assets/images/projectAVIF/chef/chefBottomImgAVIF.avif";
import chefVideoTwo from "./assets/videos/chefVideoTwo.mp4";
import chefVideoOne from "./assets/videos/chefVideoOne.mp4";
import chefVideoThree from "./assets/videos/chefVideoThree.mp4";

import decsCover from "./assets/projectImage/decs/decsCover.png";
import decsCoverWebp from "./assets/images/projectWebp/decs/decsCoverWebp.webp";
import decsCoverAVIF from "./assets/images/projectAVIF/decs/decsCoverAVIF.avif";
import decsSection16 from "./assets/projectImage/decs/decsSection16.png";
import decsSection16AVIF from "./assets/images/projectAVIF/decs/decsSection16AVIF.avif";
import decsSection16Webp from "./assets/images/projectWebp/decs/decsSection16Webp.webp";
import decsVideoOne from "./assets/videos/decsVideoOne.mp4";
import decsVideoTwo from "./assets/videos/decsVideoTwo.mp4";
import decsVideoThree from "./assets/videos/decsVideoThree.mp4";
import decsVideoFour from "./assets/videos/decsVideoFour.mp4";

import slide1 from "./assets/slide1.png";
import slide2 from "./assets/slide2.png";
import slide3 from "./assets/slide3.png";
import b1 from "./assets/blogone/b1.png";
import b2 from "./assets/blogone/b2.png";
import b3 from "./assets/blogone/b3.png";
import b4 from "./assets/blogone/b4.png";

import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

import Header from "./components/header";

const Project = React.lazy(() => import("./container/project"));
const About = React.lazy(() => import("./container/about"));
const PageNotFound = React.lazy(() => import("./components/page-not-found"));
const SelectedProject = React.lazy(() =>
  import("./container/selected-project")
);
const SelectedBlog = React.lazy(() => import("./container/selectedBlog"));
const Blog = React.lazy(() => import("./container/blog"));
const HomePage = React.lazy(() => import("./container/homepage"));
const ServicePage = React.lazy(() => import("./container/services"));

const projectData = [
  {
    id: 0,
    coverImage: dhyana2Cover,
    coverImageAVIF: dhyana2CoverAVIF,
    coverImageWebp: dhyana2CoverWebp,
    title: "Dhyana 2 - The smart ring that balances your body and mind",
    url: "Dhyana2-The-smart-ring-that-balances-your-body-and-mind",
    topPara: [
      "The second version of dhyana is an outcome of our in-depth understanding of our users. Users across the world loved dhyana but were yearning for more insights into their body and mind, which would assist them in living awholistic and balanced life.",
      "With dhyana 2, we devised a smart way of actively calculating the user's stress and relaxation levels, thereby instantly informing and notifying them when they're out of balance. The device monitors all the vital signs of regular devices, but its patented technology allows it to go a step further by connecting the body and the mind.",
    ],
    videoProjectAvail: true,
    videoProject: dhyana2VideoOne,
    section3Avail: true,
    section3Image1: dhyana2Section3One,
    section3Image2: dhyana2Section3Two,
    section3Image1AVIF: dhyana2Section3OneAVIF,
    section3Image2AVIF: dhyana2Section3TwoAVIF,
    section3Image1Webp: dhyana2Section3OneWebp,
    section3Image2Webp: dhyana2Section3TwoWebp,
    section3Para: [
      "Upgraded and Redesigned",
      "Maintaining the same calm and beautiful feel of the original dhyana app, the new application shows more information without becoming data heavy.",
      "Balance is the name of the game, and every proportion, graph and image highlights that balance.",
    ],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: false,
    section5Title: "",
    section5Para: "",
    section6Avail: false,
    section6Img1: "",
    section6Img2: "",
    section6Img1AVIF: "",
    section6Img2AVIF: "",
    section6Img1Webp: "",
    section6Img2Webp: "",
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: true,
    section8Image: dhyana2Section8Img,
    section8ImageAVIF: dhyana2Section8ImgAVIF,
    section8ImageWebp: dhyana2Section8ImgWebp,
    section8Para: [
      "Design & Materials",
      "Dhyana is made from unibody aluminum, and its unique design makes it lightweight and gives the user room for flexibility allowing for a comfortable fit even when worn 24x7.",
      "The accompanying dock not only acts as a perfect product accompaniment as well as to provides charge on the go.",
    ],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: false,
    section10Image: "",
    section10ImageAVIF: "",
    section10ImageWebp: "",
    section10Para: ["", "", "", ""],
    section11Avail: false,
    section11Image: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11Para: ["", ""],
    finalPoster: false,
    posterBottomImage: "",
    posterBottomImageAVIF: "",
    posterBottomImageWebp: "",
    section12Avail: true,
    section12Vid: dhyana2SmallVid,
    section12Para: [
      "Facial HRV",
      "An area for improvement and also as a go-to-market strategy is to offer the features of the ring for free to users.",
      "We realised that holding the camera with your finger to gain HRV can  be tiresome, so we created a new way of getting the user’s HRV by just pointing their front facing camera to their face. ",
    ],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: [
      "Hardware Development",
      "Industrial Design",
      "Mobile App Development",
      "UI/UX Design",
      "Web Development",
    ],
  },
  {
    id: 1,
    coverImage: dhyana1Cover,
    coverImageAVIF: dhyana1CoverAVIF,
    coverImageWebp: dhyana1CoverWebp,
    title: "Dhyana - The first smart ring that measures your mindfulness",
    url: "Dhyana-The-first-smart-ring-that-measures-your-mindfulness",
    topPara: [
      "In December 2019, we launched our first commercial product, dhyana. Dhyana is the first smart ring, that uses Heart Rate Variability (HRV) to measure how well the person is meditating. Insights such as: how effective their meditation practice was in breathing, relaxation, and focus meditations were also analyzed.",
      "Dhyana became the official meditation partner for the Indian Olympic Association and was extensively used by the Indian Athletes for the 2020 Olympic Games. The mobile application was downloaded over 250K times with 15K daily active users of the product.",
    ],
    videoProjectAvail: true,
    videoProject: dhyana1VideoOne,
    section3Avail: true,
    section3Image1: dhyana1Section3One,
    section3Image2: dhyana1Section3Two,
    section3Image1AVIF: dhyana1Section3OneAVIF,
    section3Image2AVIF: dhyana1Section3TwoAVIF,
    section3Image1Webp: dhyana1Section3OneWebp,
    section3Image2Webp: dhyana1Section3TwoWebp,
    section3Para: [
      "Innovative and Ground Breaking",
      "Using Heart Rate Variability, we were able to create algorithms that correcnt predicted how well the user meditated while performing breathing, relaxation and focus meditations.    ",
      "The ring also actively calculated Vagal Tone, or the shift in the Authonomic Nervous System from stress to relaxation.",
    ],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: false,
    section5Title: "",
    section5Para: "",
    section6Avail: false,
    section6Img1: "",
    section6Img2: "",
    section6Img1AVIF: "",
    section6Img2AVIF: "",
    section6Img1Webp: "",
    section6Img2Webp: "",
    section7Avail: true,
    section7Image: dhyana1Section7Img,
    section7ImageAVIF: dhyana1Section7ImgAVIF,
    section7ImageWebp: dhyana1Section7ImgWebp,
    section7Para: [
      "Immediate Biofeedback",
      "For the first time you can see, on screen, if you’re relaxing or focussing. The app instantly reads your state of mind, and shows it to you.",
      "Meditation Sessions Work",
      "Every meditation session is designed to help you get mindful easily. Dhyana also works with all other meditation apps.",
      "Get a Detailed Report",
      " You can go into detail about the moments in time when you were mindful and those when your mind wandered",
    ],
    section8Avail: false,
    section8Image: "",
    section8ImageAVIF: "",
    section8ImageWebp: "",
    section8Para: ["", ""],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: true,
    videoSecond: dhyana1VideoTwo,
    section10Avail: true,
    section10Image: dhyana1Section10Img,
    section10ImageAVIF: dhyana1Section10ImgAVIF,
    section10ImageWebp: dhyana1Section10ImgWebp,
    section10Para: [
      "Community",
      "We brought together the best meditation teachers from across the world to offer their signature meditations to our users for free",
      "",
      "",
    ],
    section11Avail: true,
    section11Image: dhyana1Section11Img,
    section11ImageAVIF: dhyana1Section11ImgAVIF,
    section11ImageWebp: dhyana1Section11ImgWebp,
    section11Para: [
      "Accolades",
      "Dhyana won the 2020 iF Design award, the 2021 India Design Mark and was also the official meditation partner for the Indian Olympic Team during the Tokyo Olympics",
    ],
    finalPoster: true,
    posterBottomImage: dhyana1BottomImg,
    posterBottomImageAVIF: dhyana1BottomImgAVIF,
    posterBottomImageWebp: dhyana1BottomImgWebp,
    section12AAvail: false,
    section12AVid: "",
    section12APara: ["", "", ""],
    section12Avail: false,
    section12Vid: "",
    section12Para: ["", "", ""],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: [
      "Hardware Development",
      "Industrial Design",
      "Mobile App Development",
      "UI/UX Design",
      "Web Development",
    ],
  },
  {
    id: 2,
    coverImage: holobotCover,
    coverImageAVIF: holobotCoverAVIF,
    coverImageWebp: holobotCoverWebp,
    title: "Holobot - A human like hologram that interacts with you",
    url: "Holobot-A-human-like-hologram-that-interacts-with-you",
    topPara: [
      "In 2021, we began tow ork on new forms of communication with digital interfaces beyond text and voice, and developed decs.ai. Decs.ai allowed us to create human like chatbots using deepfake and natural language processing.",
      "To enable this communication to happen seamlessly, we integrated this technology with holograms, paving a new form of communication to occur with digital humans that could provide assistance, teach, and even recommend and sell services and products.",
    ],
    videoProjectAvail: true,
    videoProject: holobotVideoOne,
    section3Avail: true,
    section3Image1: holobotSection3One,
    section3Image2: holobotSection3Two,
    section3Image1AVIF: holobotSection3OneAVIF,
    section3Image2AVIF: holobotSection3TwoAVIF,
    section3Image1Webp: holobotSection3OneWebp,
    section3Image2Webp: holobotSection3TwoWebp,
    section3Para: [
      "Language Is No Longer A Barrier",
      "The HoloBot can speak any language, and understand any language.",
      "It’s true to life speech and facial movements convince anyone that the bot is speaking in their language allowing them to suspend their disbelief and have an open and comforting conversation with the Bot.",
    ],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: false,
    section5Title: "",
    section5Para: "",
    section6Avail: false,
    section6Img1: "",
    section6Img2: "",
    posterBottomImageAVIF: hebatnegarakuBottomImgAVIF,
    posterBottomImageWebp: hebatnegarakuBottomImgWebp,
    section6Img1AVIF: "",
    section6Img2AVIF: "",
    section6Img1Webp: "",
    section6Img2Webp: "",
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: true,
    section8Image: holobotSection8Image,
    section8ImageAVIF: holobotSection8ImageAVIF,
    section8ImageWebp: holobotSection8ImageWebp,
    section8Para: [
      "Scalable",
      "The back-end chatbot of the HoloBot can be endlessly customised allowing for millions of interactions with millions of users every day.",
      "Apart from its unique communication platform, the HoloBot is securely built for it to be placed anywhere and can work tirelessly without taking a break.",
    ],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: false,
    section10Image: "",
    section10ImageAVIF: "",
    section10ImageWebp: "",
    section10Para: ["", "", "", ""],
    section11Avail: false,
    section11Image: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11Para: ["", ""],
    finalPoster: false,
    posterBottomImage: "",
    posterBottomImageAVIF: "",
    posterBottomImageWebp: "",
    section12AAvail: false,
    section12AVid: "",
    section12APara: ["", "", ""],
    section12Avail: false,
    section12Vid: "",
    section12Para: ["", "", ""],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: [
      "Artificial Intelligence",
      "Hardware Development",
      "Software Development",
    ],
  },
  {
    id: 3,
    coverImage: saffolaCover,
    coverImageAVIF: saffolaCoverAVIF,
    coverImageWebp: saffolaCoverWebp,
    title:
      "Saffola aura - A mixed reality  experience with Shipra Khanna for a premium oil brand",
    url: "Saffola-aura-A-mixed-reality-experience-with-Shipra-Khanna-for-a-premium-oil-brand",
    topPara: [
      "Saffola Aura, a new oil brand from Marico Ltd. blends Flaxseed oil to enrich Olive Oil with Omera-3 and Omega-6 fatty acids. To showcase how this oil retains the flavour of Olive Oil but makes it more nutritious, we created a unique experience with celebrity chef Shipra Khanna.",
      "At supermarkets across Mumbai, India, shoppers could wear MR glasses and watch a life-size hologram of Shipra Khanna making falafel with Saffola Aura while talking about the taste and health benefits of the unique blended oil.",
    ],
    videoProjectAvail: true,
    videoProject: saffolaVideoOne,
    section3Avail: true,
    section3Image1: saffolaSection3One,
    section3Image2: saffolaSection3Two,
    section3Image1AVIF: saffolaSection3OneAVIF,
    section3Image2AVIF: saffolaSection3TwoAVIF,
    section3Image1Webp: saffolaSection3OneWebp,
    section3Image2Webp: saffolaSection3TwoWebp,
    section3Para: [
      "Hacking Mixed Reality",
      "In 2017, the word mixed reality wasn’t even in the lexicon. We knew if we wanted to create a new experience, we had to think out of the box.",
      "We used Samsung S7 edge phones with a modified Samsung VR Gear headset to digitally augment the reality of shoppers with a virtual reality headset. ",
    ],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: true,
    section5Title: "Storyboarding an experience",
    section5Para:
      "Uncharted waters still need a plan. We worked with storyboarding artists and digital 3D experts to create a fun and thrilling experience for shoppers",
    section6Avail: true,
    section6Img1: saffolaSection6Img1,
    section6Img2: saffolaSection6Img2,
    section6Img1AVIF: saffolaSection6Img1AVIF,
    section6Img2AVIF: saffolaSection6Img2AVIF,
    section6Img1Webp: saffolaSection6Img1Webp,
    section6Img2Webp: saffolaSection6Img2Webp,
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: true,
    section8Image: saffolaSection8Image,
    section8ImageAVIF: saffolaSection8ImageAVIF,
    section8ImageWebp: saffolaSection8ImageWebp,
    section8Para: [
      "",
      "The experience was designed to educate on the health benefit but also to ensure the shoppers of the great taste.",
      "The experience was made to mimic this when Chef Khanna makes falafel and once the viewers take their MR glasses off, they’re treated to freshly made falafel.",
    ],
    section9Avail: true,
    section9Title: "Distribution",
    section9Para:
      "This installation was placed in 15 supermarkets across Mumbai over a month and created a wonderful experience for shoppers and especially their children. ",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: false,
    section10Image: "",
    section10ImageAVIF: "",
    section10ImageWebp: "",
    section10Para: ["", "", "", ""],
    section11Avail: false,
    section11Image: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11Para: ["", ""],
    finalPoster: true,
    posterBottomImage: safolaBottomImg,
    posterBottomImageAVIF: safolaBottomImgAVIF,
    posterBottomImageWebp: safolaBottomImgWebp,
    section12AAvail: false,
    section12AVid: "",
    section12APara: ["", "", ""],
    section12Avail: false,
    section12Vid: "",
    section12Para: ["", "", ""],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: ["Augmented Reality", "Mobile App Development", "UI/UX Design"],
  },
  {
    id: 4,
    coverImage: einCover,
    coverImageAVIF: einCoverAVIF,
    coverImageWebp: einCoverWebp,
    title: "Ein - A cardiologist, nurse and care-giver on the wrist",
    url: "Ein-A-cardiologist-nurse-and-care-giver-on-the-wrist",
    topPara: [
      "Ein is a wearable unlike any other. Apart from being a beautiful watch, it’s also packed full of smart health features such as EKG, SpO2, HRV, apart from the plethora of activity tracking metrics.",
      "However, what sets ein apart from the rest of the competiiton, is it’s patented features. Hold the watch to your chest, and it begins to conduct an echocardiogram on your heart, showing you how the valves are working. Hold the sensor with your other finger and measures your pulse transit time, or blood pressure.  Ein can completely redefine the way we view healthcare. ",
    ],
    videoProjectAvail: true,
    videoProject: einVideoOne,
    section3Avail: false,
    section3Image1: "",
    section3Image2: "",
    section3Image1AVIF: "",
    section3Image2AVIF: "",
    section3Image1Webp: "",
    section3Image2Webp: "",
    section3Title: "",
    section3Para: ["", ""],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: false,
    section5Title: "",
    section5Para: "",
    section6Avail: false,
    section6Img1: "",
    section6Img2: "",
    section6Img1AVIF: "",
    section6Img2AVIF: "",
    section6Img1Webp: "",
    section6Img2Webp: "",
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: true,
    section8Image: einSection8Image,
    section8ImageAVIF: einSection8ImageAVIF,
    section8ImageWebp: einSection8ImageWebp,
    section8Para: [
      "Unlocking Non-Obtrusive Blood Pressure",
      "Pulse transit time is directly related to our blood pressure.",
      "Ein is uniquely designed as it places the second EKG Sensor and PPG sensor together. When the user touches the sensor with their finger, ein  estimates the time it takes for a pulse to travel from the heart to the finger, thereby determining the user’s blood pressure.",
    ],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: true,
    section10Image: einSection10Img,
    section10ImageAVIF: einSection10ImgAVIF,
    section10ImageWebp: einSection10ImgWebp,
    section10Para: [
      "Beautiful & Feature Packed",
      "Ein Watch is fitted with a SIM card, allowing users to make calls. It senses accurate SpO2, comparable to medical devices. It also uses AI to detect unhealthy habits such as smoking",
      "All these features are packaged in a beautiful device, with a stunning AMOLED display and a buttery smooth interface.",
      "Ein was a finalist for the Qualcomm India Design Challenge in 2018, and has gained the complete Qualcomm Development License",
    ],
    section11Avail: true,
    section11Image: einSection11Img,
    section11ImageAVIF: einSection11ImgAVIF,
    section11ImageWebp: einSection11ImgWebp,
    section11Para: [
      "Accuracy",
      "Our state of the art algorithm has been tested and shows +/- 8mm Hg adherence to blood pressure in both normal, hypertensive and hypotensive patients",
    ],
    finalPoster: true,
    posterBottomImage: einBottomImg,
    posterBottomImageAVIF: einBottomImgAVIF,
    posterBottomImageWebp: einBottomImgWebp,
    section12AAvail: false,
    section12AVid: "",
    section12APara: ["", "", ""],
    section12Avail: false,
    section12Vid: "",
    section12Para: ["", "", ""],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: ["Hardware Development", "Industrial Design", "UI/UX Design"],
  },
  {
    id: 5,
    coverImage: axarCover,
    coverImageAVIF: axarCoverAVIF,
    coverImageWebp: axarCoverWebp,
    title: "ARxAR - Creating AR Rahman’s personal AR application",
    url: "ARxAR-Creating-AR-Rahmans-personal-AR-application",
    topPara: [
      "AR Rahman, contemporary India’s most famous musician, has been thrilling and delighting his fans for decades. In 2017, we asked us to help him each out to his fans and supporters through a unique augmented reality experience.",
      "We made that happen by making his personal app: ARxAR. We captured AR Rahman’s music, his thoughts and musings in life-size Augmented Reality and conveyed the same to his millions of fans through an exclusive partnership with Apple.",
    ],
    videoProjectAvail: true,
    videoProject: axarVideoOne,
    section3Avail: true,
    section3Image1: axarSection3One,
    section3Image2: axarSection3Two,
    section3Image1AVIF: axarSection3OneAVIF,
    section3Image2AVIF: axarSection3TwoAVIF,
    section3Image1Webp: axarSection3OneWebp,
    section3Image2Webp: axarSection3TwoWebp,
    section3Para: [
      "Creating an AR of AR",
      "ARxAR was the first mobile application to use Apple’s ARKit, allowing the app to instantly detect the floor without the need for a marker.",
      "This allowed users to keep AR Rahman in any location and take a selfie with him after viewing his message.",
    ],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: true,
    section5Title: "Appreciation",
    section5Para:
      "Fans across the world showered appreciation on the application as it was downloaded over 1 Million times and still hold a 4 Star rating till today.",
    section6Avail: false,
    section6Img1: "",
    section6Img2: "",
    section6Img1AVIF: "",
    section6Img2AVIF: "",
    section6Img1Webp: "",
    section6Img2Webp: "",
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: true,
    section8Image: axarSection8Image,
    section8ImageAVIF: axarSection8ImageAVIF,
    section8ImageWebp: axarSection8ImageWebp,
    section8Para: [
      "",
      "The experience was designed to educate on the health benefit but also to ensure the shoppers of the great taste.",
      "The experience was made to mimic this when Chef Khanna makes falafel and once the viewers take their MR glasses off, they’re treated to freshly made falafel.",
    ],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: false,
    section10Image: "",
    section10ImageAVIF: "",
    section10ImageWebp: "",
    section10Para: ["", "", "", ""],
    section11Avail: false,
    section11Image: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11Para: ["", ""],
    finalPoster: true,
    posterBottomImage: axarBottomImg,
    posterBottomImageAVIF: axarBottomImgAVIF,
    posterBottomImageWebp: axarBottomImgWebp,
    section12AAvail: false,
    section12AVid: "",
    section12APara: ["", "", ""],
    section12Avail: false,
    section12Vid: "",
    section12Para: ["", "", ""],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: ["Augmented Reality", "Mobile App Development", "UI/UX Design"],
  },
  {
    id: 6,
    coverImage: inviteCMCover,
    coverImageAVIF: inviteCMCoverAVIF,
    coverImageWebp: inviteCMCoverWebp,
    title: "Invite CM - An AR appeal by the CM inside your home",
    url: "Invite-CM-An-AR-appeal-by-the-CM-inside-your-home",
    topPara: [
      "In 2017, the ruling party of Maharashtra, the BJP, asked us to create a unique experience for the party and for the citizens of India’s financial capital, Mumbai for the upcoming 2017 BMC (municipal) elections.",
      "We took the CM, who was well known and loved in the circles to every home by creating an Android application called InviteCM, where the CM would appear in the citizen’s residence and appeal for their votes and also give them an opportunity to take a selfie with him.",
    ],
    videoProjectAvail: true,
    videoProject: inviteCmVideoOne,
    section3Avail: true,
    section3Image1: inviteCMSection3One,
    section3Image2: inviteCMSection3Two,
    section3Image1AVIF: inviteCMSection3OneAVIF,
    section3Image2AVIF: inviteCMSection3TwoAVIF,
    section3Image1Webp: inviteCMSection3OneWebp,
    section3Image2Webp: inviteCMSection3TwoWebp,
    section3Para: [
      "Lifesize Augmented Reality Hologram",
      "Invite CM used revolutionary AR technology at that time. It would detect the marker on the floor, and based on the size of marker would make a life-sized hologram of Devendra Fadnavis appear.",
      "It was revolutionary at its time, and made the application easy to use on any android device.",
    ],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: true,
    section5Title: "Storyboarding an experience",
    section5Para:
      "Uncharted waters still need a plan. We worked with storyboarding artists and digital 3D experts to create a fun and thrilling experience for shoppers",
    section6Avail: false,
    section6Img1: "",
    section6Img2: "",
    section6Img1AVIF: "",
    section6Img2AVIF: "",
    section6Img1Webp: "",
    section6Img2Webp: "",
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: true,
    section8Image: inviteCMSection8Image,
    section8ImageAVIF: inviteCMSection8ImageAVIF,
    section8ImageWebp: inviteCMSection8ImageWebp,
    section8Para: [
      "",
      "The application was downloaded over 100K times and lead to BJP winning the its highest number of seats in the BMC elections since independence",
      "",
    ],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: false,
    section10Image: "",
    section10ImageAVIF: "",
    section10ImageWebp: "",
    section10Para: ["", "", "", ""],
    section11Avail: false,
    section11Image: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11Para: ["", ""],
    finalPoster: false,
    posterBottomImage: "",
    posterBottomImageAVIF: "",
    posterBottomImageWebp: "",
    section12AAvail: false,
    section12AVid: "",
    section12APara: ["", "", ""],
    section12Avail: false,
    section12Vid: "",
    section12Para: ["", "", ""],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: [
      "Augmented Reality",
      "Mobile App Development",
      "Political Strategy",
      "UI/UX Design",
    ],
  },
  {
    id: 7,
    coverImage: CMtalksCover,
    coverImageAVIF: CMtalksCoverAVIF,
    coverImageWebp: CMtalksCoverWebp,
    title: "CMTalks - Every poster into a messaging platform",
    url: "CMTalks-Every-poster-into-a-messaging-platform",
    topPara: [
      "In 2018, the Karnataka State Congress Committee, which was ruling the state of Karnataka, reached out to us to create a novel campaign for the chief minister of the state, which would allow him to quickly and instantly respond to any criticisms the opposition had made on him uniquely and interactively.",
      "This multilingual one-of-a-kind campaign used AR to make every political poster have a new and up-to-date message every day from the Chief Minister, Mr. Siddaramaiah, resulting in the party gaining power back in the state after the elections.",
    ],
    videoProjectAvail: true,
    videoProject: cMtalksVideoOne,
    section3Avail: false,
    section3Image1: "",
    section3Image2: "",
    section3Image1AVIF: "",
    section3Image2AVIF: "",
    section3Image1Webp: "",
    section3Image2Webp: "",
    section3Title: "",
    section3Para: ["", ""],
    section4Avail: true,
    section4Image: cmTalksSection4Img,
    section4ImageAVIF: cmTalksSection4ImgAVIF,
    section4ImageWebp: cmTalksSection4ImgWebp,
    section4Para: [
      "CMTalks used a novem method of communicating with voters. At every village, bus-stop and on billboards, we designed three different forms of banners.",
      "The green banner related to the development work that the party had done for the state.",
      "The blue banner countered any opposition charge with a punchy dialogue that enthralled voters.",
      "The yellow banner showcased the CM’s softer side where he addressed grievances and talked about the beneficiaries of his government’s schemes.",
      "The impact of having three banners gave a lot of legroom to create messaging that directly impacted the voters on a personalised and targeted manner.",
    ],
    section5Avail: true,
    section5Title: "Launch",
    section5Para:
      "The CMTalks app was launched by the chief minister himself along with many government dignitaries. The app was downloaded over 200,000 times before the election date",
    section6Avail: true,
    section6Img1: cmTalksSection6Img1,
    section6Img2: cmTalksSection6Img2,
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: false,
    section8Image: "",
    section8ImageAVIF: "",
    section8ImageWebp: "",
    section8Para: ["", ""],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: false,
    section10Image: "",
    section10ImageAVIF: "",
    section10ImageWebp: "",
    section10Para: ["", "", "", ""],
    section11Avail: false,
    section11Image: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11Para: ["", ""],
    finalPoster: true,
    posterBottomImage: cmTalksLast,
    posterBottomImageAVIF: cmTalksLastAVIF,
    posterBottomImageWebp: cmTalksLastWebp,
    section12AAvail: false,
    section12AVid: "",
    section12APara: ["", "", ""],
    section12Avail: false,
    section12Vid: "",
    section12Para: ["", "", ""],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: [
      "Augmented Reality",
      "Mobile App Development",
      "Political Strategy",
      "UI/UX Design",
    ],
  },
  {
    id: 8,
    coverImage: hebatnegarakuCover,
    coverImageAVIF: hebatnegarakuCoverAVIF,
    coverImageWebp: hebatnegarakuCoverWebp,
    title: "Hebatnegaraku - Connecting voters with their representatives",
    url: "Hebatnegaraku-Connecting-voters-with-their-representatives",
    topPara: [
      "Malaysia's ruling government was going to the polls in 2018 and was hurt by scandals where the Prime Minister, Mr. Najib Razak, was implicated. The Barisan Nasional (BN) reached out to us to create a campaign that took the focus away from the scandal and brought it back onto the local representatives.",
      "Since the BN was the only party that won every election since Malay independence, we focussed on having the PM introduce the representatives and connect them with the voters in a unique AR experience.",
    ],
    videoProjectAvail: true,
    videoProject: hebatnegarakuVideoOne,
    section3Avail: true,
    section3Image1: hebatnegarakuSection3One,
    section3Image2: hebatnegarakuSection3Two,
    section3Image1AVIF: hebatnegarakuSection3OneAVIF,
    section3Image2AVIF: hebatnegarakuSection3TwoAVIF,
    section3Image1Webp: hebatnegarakuSection3OneWebp,
    section3Image2Webp: hebatnegarakuSection3TwoWebp,
    section3Para: [
      "Poster Based AR",
      "The posters of the Prime Minister and the local representatives come alive when the app scans the image.",
      "This campaign was expanded in its effect with the use of Holograms that would go place-to-place to spread the message of the PM and the local parliamentarian",
    ],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: false,
    section5Title: "",
    section5Para: "",
    section6Avail: false,
    section6Img1: "",
    section6Img2: "",
    section6Img1AVIF: "",
    section6Img2AVIF: "",
    section6Img1Webp: "",
    section6Img2Webp: "",
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: true,
    section8Image: hebatnegarakuSection8Image,
    section8ImageAVIF: hebatnegarakuSection8ImageAVIF,
    section8ImageWebp: hebatnegarakuSection8ImageWebp,
    section8Para: [
      "Democracy At Work",
      "The effect of projecting parliamentarians had a large impact on the elections.",
      "While the Barisan Nasional did suffer a historic defeat, its numbers in the parliament were far higher than any pundit had predicted, as this campaign strengthened the connection between them and their representatives.",
    ],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: false,
    section10Image: "",
    section10ImageAVIF: "",
    section10ImageWebp: "",
    section10Para: ["", "", "", ""],
    section11Avail: false,
    section11Image: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11Para: ["", ""],
    finalPoster: true,
    posterBottomImage: hebatnegarakuBottomImg,
    posterBottomImageAVIF: hebatnegarakuBottomImgAVIF,
    posterBottomImageWebp: hebatnegarakuBottomImgWebp,
    section12AAvail: false,
    section12AVid: "",
    section12APara: ["", "", ""],
    section12Avail: false,
    section12Vid: "",
    section12Para: ["", "", ""],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: [
      "Artificial Intelligence",
      "Augmented Reality",
      "Political Strategy",
      "UI/UX Design",
    ],
  },
  {
    id: 9,
    coverImage: appStarCover,
    coverImageAVIF: appStarCoverAVIF,
    coverImageWebp: appStarCoverWebp,
    title:
      "AppStar -  Movie stars step out of their posters and into the real world",
    url: "AppStar-Movie-stars-step-out-of-their-posters-and-into-the-real-world",
    topPara: [
      "Ramanaidu Studios was launching a Telugu movie Nene Raju Nene Mantri, with stars Rana Dagubati and Kajol as leads. This movie had high industry expectations as it was the first to be headlined by Rana after the hit, Bahubali. When the studio reached out to us to do an innovative campaign for this movie, we ideated and created AppStar.",
      "AppStar is a mobile application that was movie agnostic and allowed studios to engage users via AR, whereby fans could interact with stars by just pointing their camera at a poster.",
    ],
    videoProjectAvail: true,
    videoProject: appStarVideoOne,
    section3Avail: true,
    section3Image1: appStarSection3One,
    section3Image2: appStarSection3Two,
    section3Image1AVIF: appStarSection3OneAVIF,
    section3Image2AVIF: appStarSection3TwoAVIF,
    section3Image1Webp: appStarSection3OneWebp,
    section3Image2Webp: appStarSection3TwoWebp,
    section3Para: [
      "Starstruck Celebrities",
      "AR for movie promotion wasn’t new, however this was the first time the celebrity stepped out of the movie poster and posed for a photograph.",
      "Fans and other celebrities seized this opportunity to experience a new form of digital interaction.",
    ],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: false,
    section5Title: "",
    section5Para: "",
    section6Avail: false,
    section6Img1: "",
    section6Img2: "",
    section6Img1AVIF: "",
    section6Img2AVIF: "",
    section6Img1Webp: "",
    section6Img2Webp: "",
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: true,
    section8Image: appStarSection8Image,
    section8ImageAVIF: appStarSection8ImageAVIF,
    section8ImageWebp: appStarSection8ImageWebp,
    section8Para: [
      "",
      "Apart from the app being downloaded over 500K times, the movie gained thousands of dollars of free publicity due to the application.",
      "Pictures posted on social media and at movie theatres allowed people to connect with the characters within the film, resulting in returns that exceeded three times its budget",
    ],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: false,
    section10Image: "",
    section10ImageAVIF: "",
    section10ImageWebp: "",
    section10Para: ["", "", "", ""],
    section11Avail: false,
    section11Image: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11Para: ["", ""],
    finalPoster: true,
    posterBottomImage: appstarBottomImg,
    posterBottomImageAVIF: appstarBottomImgAVIF,
    posterBottomImageWebp: appstarBottomImgWebp,
    section12AAvail: false,
    section12AVid: "",
    section12APara: ["", "", ""],
    section12Avail: false,
    section12Vid: "",
    section12Para: ["", "", ""],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: ["Augmented Reality", "Mobile App Development", "UI/UX Design"],
  },
  {
    id: 10,
    coverImage: dhunCover,
    coverImageAVIF: dhunCoverAVIF,
    coverImageWebp: dhunCoverWebp,
    title: "dhün - A hi-fi speaker that is also an Indian handicraft",
    url: "dhün-A-hi-fi-speaker-that-is-also-an-Indian-handicraft",
    topPara: [
      "In 2021, we began to create our third indigenous product: dhün, a speaker that would rival the best in the world in terms of audio quality and would be made by hand and crafted by Indian artisans of different artistic schools.",
      "Dhün imagines handicrafts to become functional art, that exemplify and expand the artform to establish a new form of cottage industry, one that is interactive, tactile and decorative.",
    ],
    videoProjectAvail: false,
    videoProject: "",
    section3Avail: true,
    section3Image1: dhunSection3One,
    section3Image2: dhunSection3Two,
    section3Image1AVIF: dhunSection3OneAVIF,
    section3Image2AVIF: dhunSection3TwoAVIF,
    section3Image1Webp: dhunSection3OneWebp,
    section3Image2Webp: dhunSection3TwoWebp,
    section3Para: [
      "Beauty In The Detail & Endlessly Customisable",
      "Expert craftsmen and artists from across India have been engaged to create unique and stunning handmade art making each speaker unique. ",
      "The aesthetics are minimalist and capable of being completely hand made and hand assembled allowing users to pick the art of their choice.",
    ],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: false,
    section5Title: "",
    section5Para: "",
    section6Avail: true,
    section6Img1: dhunSection6Img1,
    section6Img2: dhunSection6Img2,
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: true,
    section8Image: dhunSection8Image,
    section8ImageAVIF: dhunSection8ImageAVIF,
    section8ImageWebp: dhunSection8ImageWebp,
    section8Para: [
      "High Definition Sound",
      "Dhün is a 60W active speaker with a 20mm port and is driven by a 4.4” subwoofer and a 1” tweeter. It is expertly tuned to fill any room with high definition sound.. ",
      "",
    ],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: false,
    section10Image: "",
    section10ImageAVIF: "",
    section10ImageWebp: "",
    section10Para: ["", "", "", ""],
    section11Avail: false,
    section11Image: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11Para: ["", ""],
    finalPoster: false,
    posterBottomImage: "",
    posterBottomImageAVIF: "",
    posterBottomImageWebp: "",
    section12AAvail: false,
    section12AVid: "",
    section12APara: ["", "", ""],
    section12Avail: false,
    section12Vid: "",
    section12Para: ["", "", ""],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: ["Hardware Development", "Industrial Design"],
  },
  {
    id: 11,
    coverImage: odishaCover,
    coverImageAVIF: odishaCoverAVIF,
    coverImageWebp: odishaCoverWebp,
    title: "Mo Naveen Mo Ghare - CM takes a selfie with 10 Million voters",
    url: "Mo-Naveen-Mo-Ghare-CM-takes-a-selfie-with-10-Million-voters",
    topPara: [
      "In 2019, the Indian state of Odisha was headed for elections. The Biju Janta Dal, which had been in power for 20 years was facing anti-incumbency and a tough battle from the BJP, the party that was ruling the country. ",
      "We were approached to create a unique campaign for the Chief Minister, Mr. Naveen Patnaik, who the people of Odisha loved. To facilitate this, we visited 2.5 million homes in Odisha, showed the voters an AR hologram of the CM, and then proceeded to take a selfie with their families and the CM. This selfie was printed and laminated instantly and handed back to the voter as a memento.",
    ],
    videoProjectAvail: true,
    videoProject: naveenVideone,
    section3Avail: true,
    section3Image1: odishaSection3One,
    section3Image2: odishaSection3Two,
    section3Image1AVIF: odishaSection3OneAVIF,
    section3Image2AVIF: odishaSection3TwoAVIF,
    section3Image1Webp: odishaSection3OneWebp,
    section3Image2Webp: odishaSection3TwoWebp,
    section3Para: [
      "A Pocketful Of Mementos",
      "Odisha, in 2019, had a low smartphone coverage, so application-based campaigns that relied on voters to download them weren’t going to work. ",
      "So we built a 1000-member workforce to visit homes, door-to-door, show them an AR hologram of the CM and then take a selfie with them and the CM. This selfie was handed back to them instantly as a photograph keepsake, which could easily fit into any wallet. ",
    ],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: false,
    section5Title: "",
    section5Para: "",
    section6Avail: false,
    section6Img1: "",
    section6Img2: "",
    section6Img1AVIF: "",
    section6Img2AVIF: "",
    section6Img1Webp: "",
    section6Img2Webp: "",
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: true,
    section8Image: odishaSection8Image,
    section8ImageAVIF: odishaSection8ImageAVIF,
    section8ImageWebp: odishaSection8ImageWebp,
    section8Para: [
      "To Many: Their First Photographs",
      "Odisha, one of the lesser developed and tribal states of India, had villages placed in virtually inaccessible areas. ",
      "The people had limited resources and many never even had a photograph of themselves taken. This was their first photograph, and that too with the chief minister himself. ",
    ],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: true,
    section10Image: odishaSection10Img,
    section10ImageAVIF: odishaSection10ImgAVIF,
    section10ImageWebp: odishaSection10ImgWebp,
    section10Para: [
      "Say ‘Cheese’",
      "The novel approach of taking a photo and handing it back meant that even those who opposed the government now had a photograph of themselves smiling next to the chief minister.",
      "",
      "",
    ],
    section11Avail: false,
    section11Image: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11Para: ["", ""],
    finalPoster: true,
    posterBottomImage: odishaBottomImg,
    posterBottomImageAVIF: odishaBottomImgAVIF,
    posterBottomImageWebp: odishaBottomImgWebp,
    section12AAvail: false,
    section12AVid: "",
    section12APara: ["", "", ""],
    section12Avail: false,
    section12Vid: "",
    section12Para: ["", "", ""],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: [
      "Artificial Intelligence",
      "Augmented Reality",
      "Mobile App Development",
      "Political Strategy",
      "Software Development",
      "UI/UX Design",
    ],
  },
  {
    id: 12,
    coverImage: kejriwalCover,
    coverImageAVIF: kejriwalCoverAVIF,
    coverImageWebp: kejriwalCoverWebp,
    title: " Welcome Kejriwal - A party manifesto as a viral sensation",
    url: " Welcome Kejriwal - A party manifesto as a viral sensation",
    topPara: [
      "The 2020 election in the Indian state of Delhi was seen as a tough contest. The city held the central government from the BJP, but the city-state was run by the opposition party AAP, with Chief Minister Arvind Kejriwal contesting for his third term.",
      "We worked with the party to devise the best way to combat anti-incumbency by allowing voters to have a virtual chat with the CM and hear about the work done so far. This interaction allowed voters to see the CM’s thoughts and views on multiple issues and became viral amongst the voters of the city. ",
    ],
    videoProjectAvail: true,
    videoProject: kejriwalVideoOne,
    section3Avail: false,
    section3Image1: "",
    section3Image2: "",
    section3Image1AVIF: "",
    section3Image2AVIF: "",
    section3Image1Webp: "",
    section3Image2Webp: "",
    section3Para: ["", "", ""],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: false,
    section5Title: "",
    section5Para: "",
    section6Avail: false,
    section6Img1: "",
    section6Img2: "",
    section6Img1AVIF: "",
    section6Img2AVIF: "",
    section6Img1Webp: "",
    section6Img2Webp: "",
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: true,
    section8Image: kejriwalsection8Image,
    section8ImageAVIF: kejriwalsection8ImageAVIF,
    section8ImageWebp: kejriwalsection8ImageWebp,
    section8Para: [
      "Reimagining A Manifesto",
      "Large party manifestos from India are seldom read.",
      "We created a new form of a manifesto which is delivered by the chief minister, who rings a bell and requests an audience with the viewer. ",
    ],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: false,
    section10Image: "",
    section10ImageAVIF: "",
    section10ImageWebp: "",
    section10Para: ["", "", "", ""],
    section11Avail: false,
    section11Image: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11Para: ["", ""],
    finalPoster: true,
    posterBottomImage: kejriwalBottomImg,
    posterBottomImageAVIF: kejriwalBottomImgAVIF,
    posterBottomImageWebp: kejriwalBottomImgWebp,
    section12AAvail: false,
    section12AVid: "",
    section12APara: ["", "", ""],
    section12Avail: true,
    section12Vid: kejriwalVideoTwo,
    section12Para: [
      "A Welcome Virus",
      "The impact was instant. Viral videos of the CM ringing the bell and asking to enter your home were circulated as memes by voters themselves.",
      "",
    ],
    section13Avail: true,
    section13Image: kejriwalSection13Img,
    section13ImageAVIF: kejriwalSection13ImgAVIF,
    section13ImageWebp: kejriwalSection13ImgWebp,
    section13Para: [
      "Say ‘Cheese’!",
      "The novel approach of taking a photo and handing it back meant that even those who opposed the government now had a photograph of themselves smiling next to the chief minister.",
    ],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: ["Artificial Intelligence", "Political Strategy", "Web Development"],
  },
  {
    id: 13,
    coverImage: chefCover,
    coverImageAVIF: chefCoverAVIF,
    coverImageWebp: chefCoverWebp,
    title: "MastAR Chef -  Use AR to become  a masterchef",
    url: "MastAR Chef -  Use AR to become  a masterchef",
    topPara: [
      "In 2020, we were asked to make a unique experience by one of India’s leading food brands, ITC. The objective was to utilise the skills and expertise of ITC’s trained and worldclass chefs to help train normal cooks on preparing ITC quality food at their home, a real need during the pandemic.",
      "We decided to take this challenge and made the ITC Masterchef avatar, a mini chef who appears with the cook aiding them in the process of cooking and helping them deliver a great plate of food that had the taste, sight and smells of an ITC restaurant. ",
    ],
    videoProjectAvail: true,
    videoProject: chefVideoOne,
    section3Avail: false,
    section3Image1: "",
    section3Image2: "",
    section3Image1AVIF: "",
    section3Image2AVIF: "",
    section3Image1Webp: "",
    section3Image2Webp: "",
    section3Para: ["", "", ""],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: false,
    section5Title: "",
    section5Para: "",
    section6Avail: false,
    section6Img1: "",
    section6Img2: "",
    section6Img1AVIF: "",
    section6Img2AVIF: "",
    section6Img1Webp: "",
    section6Img2Webp: "",
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: true,
    section8Image: chefSection8Img,
    section8ImageAVIF: chefSection8ImgAVIF,
    section8ImageWebp: chefSection8ImgWebp,
    section8Para: [
      "Creating The Chef",
      "Our chef design was inspired by the character of Remy from the Pixar film Ratatouille. ",
      "Our chef was iconic, yet fun and playful for the users to have a completely immersive and enjoyable experience using the application.",
    ],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: true,
    section10Image: chefSection10Img,
    section10ImageAVIF: chefSection10ImgAVIF,
    section10ImageWebp: chefSection10ImgWebp,
    section10Para: [
      "Appetising 3D Food’",
      "Every aspect of the food, from the charred detail on mushroom to the colour of the microgreens, was carefully photographed and mapped onto 3D objects to create delicious sumptious food completrly in 3D.",
      "",
      "",
    ],
    section11Avail: false,
    section11Image: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11Para: ["", ""],
    finalPoster: true,
    posterBottomImage: chefBottomImg,
    posterBottomImageAVIF: chefBottomImgAVIF,
    posterBottomImageWebp: chefBottomImgWebp,
    section12AAvail: true,
    section12AVid: chefVideoTwo,
    section12APara: [
      "Teaching Through interactive AR",
      "To help the users learn how to chop an onion finely without cuttng their fingers, we created magical hands that pick up the knife and cut the onion.The impact was instant. Viral videos of the CM ringing the bell and asking to enter your home were circulated as memes by voters themselves.",
      "The entire detail can be seen from multiple angles and sides.",
    ],
    section12Avail: true,
    section12Vid: chefVideoThree,
    section12Para: [
      "The Art Of Plating",
      "The tiny chef guides the user on the plating process, and  3D food appears to magically fall on the user’s plate to let them appreciate how the food would look on their crockery. ",
      "",
    ],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: true,
    section14Title: "Tablet + Phone Application",
    section14Para:
      "The entire application was made in a record time of just one month. The iOS beta is free to download here.",
    section15Avail: false,
    section15Vid: "",
    section15Para: ["", "", ""],
    section16Avail: false,
    section16Image: "",
    section16ImageAVIF: "",
    section16ImageWebp: "",
    section16Para: ["", ""],
    genre: ["Augmented Reality", "Mobile App Development", "UI/UX Design"],
  },
  {
    id: 14,
    coverImage: decsCover,
    coverImageAVIF: decsCoverAVIF,
    coverImageWebp: decsCoverWebp,
    title: "DECS.AI - AI Powered Deepfake Enabled Chat Service",
    url: "DECS.AI - AI Powered Deepfake Enabled Chat Service",
    topPara: [
      "In 2018, we asked a radical idea, what if a chatbot like Siri or Amazon Alexa could not only sound human, but also appear as one? Our efforts helped us launch Decs.ai in 2021, where we use our custom built algorithms to utilise Natural Language Processing and DeepFake technologies to allow users to converse with chatbots as if they are real.",
      "Decs.ai is a shift in the conversation chatbot paradigm, allowing companies, educaational institutes and businesses to fully utilise any screen, be at a phone display, a kiosk, TV panel or even a hologram.",
    ],
    videoProjectAvail: true,
    videoProject: decsVideoOne,
    section3Avail: false,
    section3Image1: "",
    section3Image2: "",
    section3Image1AVIF: "",
    section3Image2AVIF: "",
    section3Image1Webp: "",
    section3Image2Webp: "",
    section3Para: ["", "", ""],
    section4Avail: false,
    section4Image: "",
    section4ImageAVIF: "",
    section4ImageWebp: "",
    section4Para: ["", "", "", "", ""],
    section5Avail: false,
    section5Title: "",
    section5Para: "",
    section6Avail: false,
    section6Img1: "",
    section6Img2: "",
    section6Img1AVIF: "",
    section6Img2AVIF: "",
    section6Img1Webp: "",
    section6Img2Webp: "",
    section7Avail: false,
    section7Image: "",
    section7ImageAVIF: "",
    section7ImageWebp: "",
    section7Para: ["", "", "", "", "", ""],
    section8Avail: false,
    section8Image: "",
    section8ImageAVIF: "",
    section8ImageWebp: "",
    section8Para: ["", "", ""],
    section9Avail: false,
    section9Title: "",
    section9Para: "",
    videoSecondAvail: false,
    videoSecond: "",
    section10Avail: false,
    section10Image: "",
    section10ImageAVIF: "",
    section10ImageWebp: "",
    section10Para: ["", "", "", ""],
    section11Avail: false,
    section11Image: "",
    section11ImageAVIF: "",
    section11ImageWebp: "",
    section11Para: ["", ""],
    finalPoster: false,
    posterBottomImage: "",
    posterBottomImageAVIF: "",
    posterBottomImageWebp: "",
    section12AAvail: true,
    section12AVid: decsVideoTwo,
    section12APara: [
      "Making The Dataset",
      "We wanted to create the most true-to-life human chatbots. In this pursuit we not only wrote a custom script for the models, but shot them in 4K RAW format to get the best results",
      "",
    ],
    section12Avail: true,
    section12Vid: decsVideoThree,
    section12Para: [
      "Humanised ChatBots",
      "The outcome of the chatbots were real, warm and friendly, with natual speaking tones. Messages were delivered with a warm and friendly face enabling ease of implementation across platforms.",
      "",
    ],
    section13Avail: false,
    section13Image: "",
    section13ImageAVIF: "",
    section13ImageWebp: "",
    section13Para: ["", ""],
    section14Avail: false,
    section14Title: "",
    section14Para: "",
    section15Avail: true,
    section15Vid: decsVideoFour,
    section15Para: [
      "Covid-19 InfoBot",
      "To showcase the effects of how well the chatbot can be used to convey information, we teamed up with a leading Indian hospital chain to make the Covid infoBot, available on www.decs.ai",
      "",
    ],
    section16Avail: true,
    section16Image: decsSection16,
    section16ImageAVIF: decsSection16AVIF,
    section16ImageWebp: decsSection16Webp,
    section16Para: [
      "The HoloBot",
      "The humanised chatbots can be viewed on the phone or screen, but the real potential for them is unleashed when placed a hologram, with complete voice interactions enabled.",
    ],
    genre: ["Augmented Reality", "Mobile App Development", "UI/UX Design"],
  },
];

const images = [
  {
    img: slide1,
    link: "https://ifworlddesignguide.com/entry/283586-dhyana",
    alt: "slide1",
    title: "blog1",
    bimg1: b1,
    bvideoProject: b2,
    bimg3: b3,
    bimg4: b4,
    blogpara1:
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
    blogpara2:
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.dafdjf",
    blogpara4: [
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
    ],

    blogpara5: [
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
    ],
    blogparatitle:
      "What we think of emerging artificial intelligence technologies.",
    author: "Bhairav Shankar",
    dateofblog: "20 May 2022",
    time: "12:14pm",
  },
  {
    img: slide2,
    link: "http://cms.newindianexpress.com/cities/bengaluru/2020/feb/12/breathe-easy-this-ring-keeps-tabs-on-your-meditation-2102202.html",
    alt: "slide2",
    title: "blog2",
    bimg1: b1,
    bvideoProject: b2,
    bimg3: b3,
    bimg4: b4,
    blogpara1:
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
    blogpara2:
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.dafdjf",
    blogpara4: [
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
    ],

    blogpara5: [
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
    ],
    blogparatitle:
      "What we think of emerging artificial intelligence technologies.",
    author: "Bhairav Shankar",
    dateofblog: "20 May 2022",
    time: "12:14pm",
  },
  {
    img: slide3,
    link: "https://heavy.com/tech/2020/06/smart-ring/",
    alt: "slide3",
    title: "blog3",
    bimg1: b1,
    bvideoProject: b2,
    bimg3: b3,
    bimg4: b4,
    blogpara1:
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
    blogpara2:
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.dafdjf",
    blogpara4: [
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
    ],

    blogpara5: [
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
    ],
    blogparatitle:
      "What we think of emerging artificial intelligence technologies.",
    author: "Bhairav Shankar",
    dateofblog: "20 May 2022",
    time: "12:14pm",
  },
  {
    img: slide1,
    link: "http://cms.newindianexpress.com/cities/bengaluru/2020/feb/12/breathe-easy-this-ring-keeps-tabs-on-your-meditation-2102202.html",
    alt: "Mindfulness Meditation App Article",
    title: "blog4",
    bimg1: b1,
    bvideoProject: b2,
    bimg3: b3,
    bimg4: b4,
    blogpara1:
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
    blogpara2:
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.dafdjf",
    blogpara4: [
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
    ],

    blogpara5: [
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
    ],
    blogparatitle:
      "What we think of emerging artificial intelligence technologies.",
    author: "Bhairav Shankar",
    dateofblog: "20 May 2022",
    time: "12:14pm",
  },
  {
    img: slide1,
    link: "http://cms.newindianexpress.com/cities/bengaluru/2020/feb/12/breathe-easy-this-ring-keeps-tabs-on-your-meditation-2102202.html",
    alt: "Mindfulness Meditation App Article",
    title: "blog5",
    bimg1: b1,
    bvideoProject: b2,
    bimg3: b3,
    bimg4: b4,
    blogpara1:
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
    blogpara2:
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.dafdjf",
    blogpara4: [
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
    ],

    blogpara5: [
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
      "Things about the project in specific. The work we’ve done etc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus fermentum semper a quisque dignissim aliquam malesuada.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst urna morbi fermentum in id lobortis. Scelerisque lorem non nibh quam. Magna accumsan, purus.",
    ],
    blogparatitle:
      "What we think of emerging artificial intelligence technologies.",
    author: "Bhairav Shankar",
    dateofblog: "20 May 2022",
    time: "12:14pm",
  },
];

function App() {
  const [posts, setPosts] = useState([]);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setPosts([...projectData]);
    setBlogs([...images]);
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Wait for 3 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  // Custom css for loader
  const override = `
  display: block;
  margin: 0 auto;
`;

  return (
    <div>
      <React.Suspense
        fallback={
          <div
            className="ring-loader"
            style={{
              minHeight: `100vh`,
              display: `grid`,
              placeContent: `center`,
            }}
          >
            <ClimbingBoxLoader
              color={"#32425c"}
              isLoading={isLoading}
              css={override}
              size={15}
            />
          </div>
        }
      >
        <Router>
          <Header />

          <div>
            <Switch>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route exact path="/about">
                <About />
              </Route>
              <Route exact path="/project">
                <Project />
              </Route>
              <Route exact path="/blog">
                <Blog />
              </Route>
              <Route exact path="/services">
                <ServicePage />
              </Route>
              <Route path="/project/:id">
                <SelectedProject posts={posts} />
              </Route>
              <Route path="/selectedblog/:id">
                <SelectedBlog blogs={blogs} />
              </Route>
              <Route component={PageNotFound} />
            </Switch>
          </div>
          {/* )}{" "} */}
        </Router>
      </React.Suspense>
    </div>
  );
}

export default App;
